import { useContext, ref, ssrRef } from '@nuxtjs/composition-api';
import { Brands, Meta, Params } from '@webplatform/cms-js-sdk';

export default (params: Params) => {
  const {
    $api: { brandsService },
  } = useContext();
  const pending = ref(false);
  const error = ssrRef(false, 'error');
  const brands = ref<Brands>();
  const meta = ref<Meta>();

  const fetchBrands = async () => {
    try {
      pending.value = true;
      const { data, meta: brandsMeta } = await brandsService.getAllBrands(
        params
      );
      brands.value = data;
      meta.value = brandsMeta;
    } catch (e) {
      error.value = true;
    } finally {
      pending.value = false;
    }
  };

  return {
    brands,
    fetchBrands,
    meta,
    pending,
    error,
  };
};
