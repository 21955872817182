import { ref, useContext } from '@nuxtjs/composition-api';

export default () => {
  const {
    $api: { formsService },
  } = useContext();
  const error = ref();
  const success = ref();
  const pending = ref(false);

  const submit = async (formData: Record<string, any>) => {
    pending.value = true;
    try {
      error.value = null;
      await formsService.sendFormData(formData);
      success.value = true;
    } catch (e) {
      error.value = e;
    } finally {
      pending.value = false;
    }
  };

  return {
    submit,
    pending,
    error,
    success,
  };
};
