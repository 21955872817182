import { useContext, ref } from '@nuxtjs/composition-api';
import { Params } from '@webplatform/cms-js-sdk';
import useErrorPage from './useErrorPage';
import useMeta from './useMeta';

// TODO: remove after add export from cms-js-sdk
// https://asbisc.atlassian.net/browse/AW-665
interface BrandCategory {
  id: number;
  name: string;
  image: string;
  slug: string;
}

export default (slug: string, params: Params) => {
  const {
    $api: { brandsService },
    ssrContext,
  } = useContext();
  const { error, showErrorPage } = useErrorPage();
  const pending = ref(false);
  const brand = ref<BrandCategory>();

  const fetchBrand = async () => {
    try {
      pending.value = true;
      const { data } = await brandsService.getBrandBySlug(slug, params);
      // @ts-ignore
      brand.value = data;
    } catch (e) {
      if (ssrContext) {
        ssrContext.res.statusCode = 404;
        error.value = 'true';
      } else showErrorPage({ statusCode: 404 });
    } finally {
      pending.value = false;
    }
  };

  useMeta(brand);

  return {
    brand,
    fetchBrand,
    pending,
  };
};
